import Button from 'components/button'
import Input from 'components/input'
import { FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCheckCircle } from 'react-icons/md'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select, { Options } from 'components/select'
import Loading from 'components/loading'
import TextArea from 'components/textArea'
import { useOrderLayout } from 'modules/order/OrderLayout'
import { yesNoOptions } from 'modules/siteSurvey/components/siteSurveyDropDown'
import { DownloadQueries } from '../queries/downloadDocuments'
import { checkApproval, getAllSelectedTemplate, getQuoteImages } from '../queries/quotes'
import { useQuoteLayout } from '../QuoteLayout'

interface IDownloadQuoteForm {
    companyName: string
    firstName: string
    familyName: string
    maleOrFemale: 'male' | 'female'
    address1: string
    address2: string
    postalCode: string
    city: string
    department: string
    sales: string
    salesSupport: string
    manual_comment: string
    templateId: string
    showInzetMachineForKeyAccountManager?: boolean
    orderNumber?: string | null
}

type ISerieImage = {
    id: number
    image: string
    series: string
    selected: boolean
}

type IQuoteImage = {
    name: string
    images: ISerieImage[]
}

const DownloadDocument = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()
    const { quoteDetail } = useQuoteLayout()
    const { quoteDetailFromOrder } = useOrderLayout()
    const { user, userExtra } = useAuth()
    const queryClient = useQueryClient()
    const [quoteImage, setQuoteImage] = useState<IQuoteImage[]>([])
    const [documentExist, setDocumentExist] = useState<boolean>(false)
    const imageUrl = process.env.REACT_APP_S3_IMAGE_URL
    const [salesSupportOptions, setSalesSupportOptions] = useState<Options[]>([])
    const [selectedImage, setSelectedImage] = useState<any>()
    const [isApproved, setIsApproved] = useState<boolean>(false)
    const isOrder = window.location.pathname.includes('order')
    const [templateOptions, setTemplateOptions] = useState<Options[]>([])

    useQuery(['templateList'], () => getAllSelectedTemplate(userExtra.role_id), {
        onSuccess: data => {
            setTemplateOptions(
                data?.data?.selected_templates.map((template: any) => ({
                    label: template.template_name,
                    value: template.template_id,
                })),
            )
        },
        onError: (error: Error) => {
            toast.error('message.fetch_template_failed')
        },
    })

    useQuery('GetSalesSupportList', () => DownloadQueries.getSalesSupportList(), {
        onSuccess: data => {
            setSalesSupportOptions(
                data?.data?.data?.map((salesSupport: any) => ({
                    label: `${salesSupport.name} (${salesSupport.email})`,
                    value: salesSupport.id,
                })),
            )
        },
        onError: () => {
            toast.error(t('message.something_went_wrong'))
        },
    })

    const { isLoading: approvalLoading } = useQuery(
        ['checkApproval'],
        () => checkApproval(parseFloat(String(quoteId))),
        {
            onSuccess: data => {
                setIsApproved(data.data.data)
            },
            onError: () => {
                toast.error(t('message.fetch_approval_failed'))
            },
        },
    )

    const downloadDocumentMutation = useMutation(
        ['downloadDocument'],
        () => DownloadQueries.downloadDocument(quoteId || ''),
        {
            onSuccess: data => {
                const disposition = data.headers['content-disposition']
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    const matches = filenameRegex.exec(disposition)
                    if (matches != null && matches[1]) {
                        const filename = matches[1].replace(/['"]/g, '')
                        const url = window.URL.createObjectURL(new Blob([data.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', filename)
                        document.body.appendChild(link)
                        link.click()
                        URL.revokeObjectURL(url)
                        document.body.removeChild(link)
                    }
                }
            },
            onError: (error: any) => {
                toast.error(t(error.message) || t('message.download_document_failed'))
            },
        },
    )

    const updateQuoteDocumentMutation = useMutation(
        ['downloadDocument'],
        (params: any) => DownloadQueries.updateQuoteDocumentDetail(quoteId || '', params),
        {
            onSuccess: () => {
                toast.success(t('message.download_document_update_success'))
                queryClient.invalidateQueries('getDocumentDetail')
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.download_document_update_failed'))
            },
        },
    )

    const downloadForm: FormikProps<IDownloadQuoteForm> = useFormik<IDownloadQuoteForm>({
        initialValues: {
            companyName: quoteDetail?.quotation_for || quoteDetailFromOrder?.quotation_for || '',
            firstName: '',
            familyName: '',
            maleOrFemale: 'male',
            address1: '',
            address2: '',
            postalCode: '',
            city: '',
            department: '',
            sales: `${user.name} (${user.email})`,
            salesSupport: '',
            manual_comment: '',
            templateId: '',
            orderNumber: '',
            showInzetMachineForKeyAccountManager: true,
        },
        validationSchema: Yup.object().shape({
            companyName: Yup.string().required(t('message.company_name_required')),
            firstName: Yup.string().required('message.first_name_required'),
            familyName: Yup.string().required('message.family_name_required'),
            maleOrFemale: Yup.string().required('message.gender_required'),
            address1: Yup.string().required('message.address1_required'),
            postalCode: Yup.string().required('message.postal_code_required'),
            city: Yup.string().required('message.city_required'),
            department: Yup.string().required('message.department_required'),
            sales: Yup.string().required('message.sales_required'),
            salesSupport: Yup.string().required('message.sales_support_required'),
            templateId: Yup.string().required('message.template_required'),
            orderNumber: Yup.string().when('ioOrder', {
                is: () => isOrder,
                then: Yup.string().required('message.order_number_required'),
            }),
        }),
        onSubmit: (values: IDownloadQuoteForm) => {
            const images = [...quoteImage]
            const documentImage = images.map(q => ({
                serieName: q.name,
                image: q.images.find(ss => ss.selected),
            }))
            const series = quoteImage.map(item => item.name)
            const hasImage = series.every(name =>
                documentImage.some(item => item.serieName === name && item.image),
            )

            if (hasImage) {
                updateQuoteDocumentMutation.mutate({
                    company_name: values.companyName,
                    first_name: values.firstName,
                    last_name: values.familyName,
                    gender: values.maleOrFemale,
                    address1: values.address1,
                    address2: values.address2,
                    postal_code: values.postalCode,
                    city: values.city,
                    department: values.department,
                    manual_comment: values.manual_comment,
                    show_inzet_machine_for_key_account_manager:
                        values.showInzetMachineForKeyAccountManager,
                    // sales: values.sales,
                    sales_support_id: values.salesSupport,
                    document_image: documentImage,
                    template_id: values.templateId,
                    order_number: isOrder ? values.orderNumber : undefined,
                })
            } else {
                toast.error('message.one_image_for_all_series_is_required')
            }
        },
    })

    const { isLoading } = useQuery(
        'getDocumentDetail',
        () => DownloadQueries.getQuoteDocumentDetail(String(quoteId)),
        {
            onSuccess: data => {
                const documentData = data?.data?.data ? data?.data?.data : undefined
                if (documentData) {
                    downloadForm.setFieldValue('companyName', documentData.company_name)
                    downloadForm.setFieldValue('firstName', documentData.first_name)
                    downloadForm.setFieldValue('familyName', documentData.last_name)
                    downloadForm.setFieldValue('maleOrFemale', documentData.gender)
                    downloadForm.setFieldValue('address1', documentData.address1)
                    downloadForm.setFieldValue('address2', documentData.address2)
                    downloadForm.setFieldValue('postalCode', documentData.postal_code)
                    downloadForm.setFieldValue('city', documentData.city)
                    downloadForm.setFieldValue('department', documentData.department)
                    downloadForm.setFieldValue('salesSupport', documentData.sales_support_id)
                    downloadForm.setFieldValue('templateId', documentData.template_id)
                    downloadForm.setFieldValue('manual_comment', documentData.doc_manual_comments)
                    downloadForm.setFieldValue('orderNumber', documentData.order_number || '')
                    downloadForm.setFieldValue(
                        'showInzetMachineForKeyAccountManager',
                        documentData.show_inzet_machine_for_key_account_manager,
                    )
                    downloadForm.setFieldValue(
                        'sales',
                        documentData.sales || `${user.name} (${user.email})`,
                    )
                    setSelectedImage(JSON.parse(String(documentData.document_images)))
                    setDocumentExist(true)
                }
            },
            onError: () => {
                toast.error(t('message.something_went_wrong'))
                setSelectedImage([])
            },
        },
    )

    useQuery(['getQueryImage', quoteId], () => getQuoteImages(parseInt(String(quoteId), 10)), {
        enabled: !!quoteId,
        onSuccess: data => {
            const { seriesImageCount, seriesImage } = data.data.data
            const tmpQuoteImage: any = Object.keys(seriesImageCount).map(serie => ({
                name: serie,
                images: seriesImage
                    .filter((ts: any) => ts.serie === serie)
                    .map((obj: any) => ({
                        ...obj,
                        selected: selectedImage?.find((img: any) => img.image.id === obj.id)
                            ? true
                            : seriesImageCount[serie] === 1,
                    })),
            }))
            setQuoteImage(tmpQuoteImage)
        },
        onError: () => {
            toast.error(t('message.fetch_image_failed'))
        },
    })

    const handleOnImageClicked = (name: string, imageId: number) => {
        const tempQuoteImage: any =
            quoteImage
                .find(q => q.name === name)
                ?.images.map(i => ({
                    id: i.id,
                    image: i.image,
                    series: i.series,
                    selected: i.id === imageId,
                })) || []
        const tempObj: any = {
            name,
            images: tempQuoteImage,
        }
        const quoteImageIndex = quoteImage.findIndex(q => q.name === name)
        const quote = quoteImage
        quote[quoteImageIndex] = tempObj
        if (quoteImageIndex !== -1) {
            setQuoteImage([...quote])
        }
    }

    if (isLoading || approvalLoading) {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Loading />
            </div>
        )
    }

    return (
        <div className="h-full w-full flex flex-col gap-4">
            <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                {t('api.download_document')}
            </p>
            <form className="flex flex-col gap-4" onSubmit={downloadForm.handleSubmit}>
                <Input
                    horizontal
                    label={t('fields.company_name')}
                    placeholder={`${t('fields.enter')} ${t('fields.company_name')}`}
                    value={downloadForm.values.companyName}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.companyName && downloadForm.errors.companyName}
                    name="companyName"
                    required
                />
                <Input
                    horizontal
                    label={t('fields.first_name')}
                    placeholder={`${t('fields.enter')} ${t('fields.first_name')}`}
                    value={downloadForm.values.firstName}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.firstName && downloadForm.errors.firstName}
                    name="firstName"
                    required
                />
                <Input
                    horizontal
                    required
                    label={t('fields.family_name')}
                    placeholder={`${t('fields.enter')} ${t('fields.family_name')}`}
                    value={downloadForm.values.familyName}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.familyName && downloadForm.errors.familyName}
                    name="familyName"
                />
                <div className="flex justify-between gap-4 items-center px-1">
                    <p>{t('api.gender')} :</p>
                    <div className="w-[70%] flex items-center gap-4">
                        <label htmlFor="male">{t('api.male')}</label>
                        <input
                            type="radio"
                            id="maleOrFemale"
                            name="maleOrFemale"
                            value="male"
                            checked={downloadForm.values.maleOrFemale === 'male'}
                            onChange={downloadForm.handleChange}
                        />
                        <label htmlFor="female">{t('api.female')}</label>
                        <input
                            type="radio"
                            id="maleOrFemale"
                            name="maleOrFemale"
                            value="female"
                            checked={downloadForm.values.maleOrFemale === 'female'}
                            onChange={downloadForm.handleChange}
                        />
                    </div>
                </div>

                <Input
                    horizontal
                    required
                    label={t('fields.address1')}
                    placeholder={`${t('fields.enter')} ${t('fields.address1')}`}
                    value={downloadForm.values.address1}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.address1 && downloadForm.errors.address1}
                    name="address1"
                />
                <Input
                    horizontal
                    label={t('fields.address2')}
                    placeholder={`${t('fields.enter')} ${t('fields.address2')}`}
                    value={downloadForm.values.address2}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.address2 && downloadForm.errors.address2}
                    name="address2"
                />
                <Input
                    horizontal
                    required
                    label={t('fields.postal_code')}
                    placeholder={`${t('fields.enter')} ${t('fields.postal_code')}`}
                    value={downloadForm.values.postalCode}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.postalCode && downloadForm.errors.postalCode}
                    name="postalCode"
                />
                <Input
                    horizontal
                    required
                    label={t('fields.city')}
                    placeholder={`${t('fields.enter')} ${t('fields.city')}`}
                    value={downloadForm.values.city}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.city && downloadForm.errors.city}
                    name="city"
                />
                <Input
                    horizontal
                    required
                    label={t('fields.department')}
                    placeholder={`${t('fields.enter')} ${t('fields.department')}`}
                    value={downloadForm.values.department}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.department && downloadForm.errors.department}
                    name="department"
                />
                <TextArea
                    horizontal
                    label={t('fields.manual_comment')}
                    placeholder={`${t('fields.enter')} ${t('fields.manual_comment')}`}
                    value={downloadForm.values.manual_comment}
                    onChange={downloadForm.handleChange}
                    name="manual_comment"
                />
                <Input
                    horizontal
                    required
                    disabled
                    label={t('fields.sales')}
                    placeholder={`${t('fields.enter')} ${t('fields.sales')}`}
                    value={downloadForm.values.sales}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.sales && downloadForm.errors.sales}
                    name="sales"
                />
                <Select
                    horizontal
                    required
                    placeHolderOption={t('fields.select_sales_support')}
                    label={t('fields.sales_support')}
                    showPlaceHolder
                    options={salesSupportOptions}
                    value={downloadForm.values.salesSupport}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.salesSupport && downloadForm.errors.salesSupport}
                    name="salesSupport"
                />
                <Select
                    horizontal
                    required
                    placeHolderOption={t('fields.select_template')}
                    label={t('fields.select_template')}
                    showPlaceHolder
                    options={templateOptions}
                    value={downloadForm.values.templateId}
                    onChange={downloadForm.handleChange}
                    error={downloadForm.touched.templateId && downloadForm.errors.templateId}
                    name="templateId"
                />
                {userExtra.role_id === ROLE_TYPE.key_account_manager && (
                    <Select
                        horizontal
                        placeHolderOption={t(
                            'fields.show_inzet_machine_for_key_account_manager_plaeholder',
                        )}
                        label={t('fields.show_inzet_machine_for_key_account_manager')}
                        showPlaceHolder
                        options={yesNoOptions}
                        value={
                            downloadForm.values.showInzetMachineForKeyAccountManager ? 'yes' : 'no'
                        }
                        onChange={e => {
                            downloadForm.setFieldValue(
                                'showInzetMachineForKeyAccountManager',
                                e.target.value === 'yes',
                            )
                        }}
                        error={
                            downloadForm.touched.showInzetMachineForKeyAccountManager &&
                            downloadForm.errors.showInzetMachineForKeyAccountManager
                        }
                        name="salesSupport"
                    />
                )}

                {isOrder && (
                    <Input
                        horizontal
                        required
                        label={t('fields.order_number')}
                        placeholder={`${t('fields.enter')} ${t('fields.order_number')}`}
                        value={downloadForm.values.orderNumber}
                        onChange={downloadForm.handleChange}
                        error={downloadForm.touched.orderNumber && downloadForm.errors.orderNumber}
                        name="orderNumber"
                    />
                )}
                <div className="flex flex-col mt-2 md:flex-row">
                    <p className="flex-1 ml-1">{t('fields.select_serie_image')}</p>
                    <div className="w-full md:w-[70%] flex flex-col gap-4">
                        {quoteImage.length > 0 ? (
                            quoteImage.map(q => (
                                <div key={q.name} className="flex gap-2 flex-col">
                                    <p>
                                        {t('api.serie')} : {q.name}
                                    </p>
                                    <div className="flex flex-wrap gap-4">
                                        {q.images?.map(i => (
                                            <div
                                                key={i.id}
                                                className={`h-28 bg-gray w-48 cursor-pointer border-2 border-gray hover:border-primary relative ${
                                                    i.selected ? 'border-green-700' : ''
                                                }`}
                                                onClick={() => handleOnImageClicked(q.name, i.id)}
                                            >
                                                {i.selected && (
                                                    <MdCheckCircle className="text-green-700 absolute top-2 left-2" />
                                                )}
                                                <img src={`${imageUrl}${i.image}`} alt="" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>{t('message.no_serie_image')}</p>
                        )}
                    </div>
                </div>
                <div className="flex w-full gap-4 justify-end">
                    <div className="w-fit">
                        <Button
                            variant="primary"
                            isLoading={updateQuoteDocumentMutation.isLoading}
                            type="submit"
                            label={t('api.save')}
                        />
                    </div>
                    {documentExist &&
                        (userExtra.role_id === ROLE_TYPE.sales_manager ||
                            userExtra.role_id === ROLE_TYPE.managing_director ||
                            (isApproved &&
                                userExtra.role_id !== ROLE_TYPE.account_manager &&
                                userExtra.key_account_manager)) && (
                            <div className="w-fit">
                                <Button
                                    variant="primary"
                                    isLoading={downloadDocumentMutation.isLoading}
                                    disabled={downloadDocumentMutation.isLoading}
                                    type="button"
                                    onClick={() => {
                                        // if (isApproved) {
                                        //     downloadDocumentMutation.mutate()
                                        // } else {
                                        //     toast.error(t('message.quote_is_not_approved'))
                                        // }
                                        downloadDocumentMutation.mutate()
                                    }}
                                    label={t('api.download')}
                                />
                            </div>
                        )}
                </div>
            </form>
        </div>
    )
}

export default DownloadDocument
